<div class="alert-dialog-wrapper">
    <i class="ph-bold ph-x close-btn" mat-dialog-close></i>
    <div class="alert-content">
        <i [class]="props.dialogIcon ?? 'ph ph-warning'" [ngClass]="props.dialogType"></i>
        <div class="dialog-title font-weight-600">
            @if (props.dialogTitle.leading) {
            <span [ngStyle]="{'color':  props.dialogTitle.leading.color}">{{props.dialogTitle.leading.text}} </span>
            }
            @if (props.dialogTitle.trailing) {
            <span [ngStyle]="{'color':  props.dialogTitle.trailing.color}">{{props.dialogTitle.trailing.text}}</span>
            }
        </div>
        <div class="dialog-msg">{{props.dialogMessage}}</div>
    </div>
    <div class="action-buttons-wrapper">
        <app-button [props]="props.secondaryButtonProps ?? {buttonText: 'Cancel', theme: BUTTON_THEME.BORDER}" (onClick)="props.onSecondaryButtonPressed?.()" mat-dialog-close />
        @if (props.primaryButtonProps) {
            <app-button [props]="props.primaryButtonProps" (onClick)="props.onPrimaryButtonPressed()" mat-dialog-close />
        } @else {
            <app-button [props]="props.dialogType == ALERT_DIALOG_TYPE.INFO ? {buttonText: 'Discard', theme: BUTTON_THEME.ORANGE } : {buttonText: 'Delete', theme: BUTTON_THEME.RED, leadingIcon: 'ph-bold ph-trash', disabled: props.actionButtonDisabled }" (onClick)="props.onPrimaryButtonPressed()" mat-dialog-close />
        } 
    </div>
</div>